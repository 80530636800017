
document.addEventListener('DOMContentLoaded', (event) => {
    /*
    Menu.setScrolling();
    Menu.listenOpen();
    PhotoSwipePlugin.presenter();
    //PikadayPlugin.init();
    Toggle.formToggle();
    LoadMore.init(
        document.getElementById('js-load-more-services'),
        document.getElementById('js-load-more-services-btn'),
        3,
        6,
        9,
        1,
        2,
        3
    );
    LoadMore.init(
        document.getElementById('js-load-more-gallery'),
        document.getElementById('js-load-more-gallery-btn'),
        6,
        6,
        6,
        2,
        2,
        3
    );
    MicroModal.init({
        disableScroll: true,
        awaitCloseAnimation: true
    });
     */
    Menu.setHeight();
    Menu.listenOpen();
    Menu.submenu();
    Slider.benefits();
    Selects.ChoicesJS();
    Cookies.init();
    objectFitImages();
    FormValidation.vivalid();
    Tab.init();
    PhotoSwipePlugin.init();
    AttrList.showHidden();
    SliderRange.init();
    Partners.closer();
    AOS.init({
        duration: 1000,
        once: true
    });

    let doneOnce = false;
    const sliderBenefits = document.getElementById('js-slider-benefits');

    function sliderBenefitsAnimation() {
        let isOnViewPort = IsScrolledIntoView.init( sliderBenefits );
        if (isOnViewPort && !doneOnce) {
            sliderBenefits.classList.add('active');
            doneOnce = true;
        }
    }

    let scroll = new SmoothScroll('a[data-scroll]', {
        offset: function (anchor, toggle) {
            return document.getElementById('menu-container').offsetHeight + 20;
        },
    });

    sliderBenefitsAnimation();

    window.addEventListener('scroll', function () {
        sliderBenefitsAnimation();
    });
});
