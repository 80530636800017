class SliderRange {

    static init() {
        const slider = document.getElementById('slider-size');

        if (slider) {
            noUiSlider.create(slider, {
                start: [123],
                step: 1,
                range: {
                    'min': [10],
                    'max': [1000]
                },
                tooltips: true,
                pips: {
                    mode: 'count',
                    values: 2,
                    density: 0,
                    stepped: true
                },
                format: {
                    // 'to' the formatted value. Receives a number.
                    to: function (value) {
                        return parseInt(value) + ' m<sup>2</sup>';
                    },
                    // 'from' the formatted value.
                    // Receives a string, should return a number.
                    from: function (value) {
                        return Number(value);
                    }
                }
            });
        }
    }
}