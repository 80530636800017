class Selects {

    static ChoicesJS() {
        const choices = document.getElementsByClassName('js-single-select');

        if (choices) {
            for (let i = 0; i < choices.length; ++i) {

                window[`var-choices-${i}`] = new Choices(
                    choices[i], {
                        searchEnabled: false,
                        itemSelectText: '',
                        shouldSort: false
                    });

                window[`var-choices-${i}`].passedElement.element.addEventListener('change', function (event) {
                    const selectElParent = event.target.closest('.form-container');
                    if (selectElParent) {
                        let helper = selectElParent.getElementsByClassName('js-single-select-helper')[0];
                        let error = selectElParent.getElementsByClassName('vivalid-error')[0];
                        if (error) {
                            error.remove();
                        }
                        helper.checked = true;
                    }
                }, false);
            }
        }
    }

    static SlimSelectPlugin() {
        const selects = document.querySelectorAll('select.js-multiple-select');

        if (selects) {
            for (let i = 0; i < selects.length; ++i) {
                window[`var-ms-${i}`] = new SlimSelect({
                    select: selects[i],
                    searchPlaceholder: 'Hledat',
                    searchText: 'Žádný výsledek.'
                });
            }
        }
    }
}
