class Partners {

    static closer() {
        const closers = document.querySelectorAll('.js-closer');

        if (closers.length > 0) {
            closers.forEach( closer => {
               closer.addEventListener('click', function () {
                  this.closest('.partners').style.display = '';
               });
            });
        }
    }
}