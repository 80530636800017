class Slider {

  /**
   * Init classic tiny slider.
   */
  static benefits() {

      const carousel = document.getElementById('js-slider-benefits');

      let items = 2;
      let gutter = 20;

      if (window.matchMedia('(min-width:375px)').matches) {
          items = 3;
          gutter = 30;
      }

      if ( carousel && window.matchMedia('(max-width:767px)').matches ) {
          let tinySlider = tns({
              container: carousel,
              mode: 'carousel',
              items: items,
              gutter: gutter,
              edgePadding: 0,
              mouseDrag: true,
              autoplayButtonOutput: false,
              controls: false,
              nav: false,
              loop: false,
              fixedWidth: 124
          });
      }
  }
}
