
class AttrList {

    static showHidden() {
        const list = document.getElementById('jsAttrList'),
            link = document.getElementById('jsAttrListShow');

        if (list && link) {
            let hiddenItems = list.querySelectorAll('.attr-list__item--hidden');

            if (hiddenItems.length > 0) {
                link.addEventListener('click', function (e) {
                   e.preventDefault();
                   hiddenItems.forEach(item => {
                      item.classList.remove('attr-list__item--hidden');
                   });
                   this.style.display = 'none';
                });
            } else {
                link.style.display = 'none';
            }
        }
    }
}
