
class Menu {

    /**
     * Set menu's height to body's padding top to make space for menu.
     */
    static setHeight() {
        const body = document.body;
        const menu = document.getElementById('menu-container');
        body.style.paddingTop = `${menu.offsetHeight}px`;
    }

    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;

        function init() {
            let menu = document.getElementById('menu-container');
            let currentScrollPos = window.pageYOffset;

            // if menu's height is bigger than scrolled area start scrolling
            if (menu.offsetHeight < currentScrollPos) {
                menu.classList.add('menu-container--is-scrolled');
            } else {
                menu.classList.remove('menu-container--is-scrolled');
            }

            prevScrollPos = currentScrollPos;
        }

        // show menu on load
        init();
        window.onscroll = function () {
            // init menu on load
            init();
        };
    }

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementsByClassName('nav')[0];

        checkbox.addEventListener('change', function(){

            if (checkbox.checked) {
                bodyScrollLock.disableBodyScroll(navList);
               //document.body.style.overflow = 'hidden';
            } else {
                bodyScrollLock.enableBodyScroll(navList);
               //document.body.style.overflow = '';
            }
        });
    }

    static submenu() {
        const toggle = document.querySelector('.js-submenu-toggle'),
            submenu = document.querySelectorAll('.js-submenu'),
            list = document.querySelector('.js-nav-list');

        if (toggle && submenu.length > 0 && list) {
            let  listChildren = document.querySelectorAll('.js-nav-list > .nav__item:not(.nav__item--submenu)');

            toggle.addEventListener('click', function (e) {


                if ( window.matchMedia('(max-width:1279px)').matches ) {
                    e.preventDefault();
                    toggle.classList.toggle('active');
                    submenu.forEach( menu => {
                        menu.classList.toggle('hidden');
                    });

                    listChildren.forEach( item => {
                        item.classList.toggle('hidden');
                    });
                }
            });
        }
    }
}
